
<template>
  <div class="page">
    <div style="display: flex; justify-content: flex-end">
      <a-icon type="close" @click="closeDetail"/>
    </div>
    <a-descriptions v-if="placeDetail.kind === 0" title="幼儿园" layout="vertical" column="3">
      <a-descriptions-item  label="名称" :span="3"> {{ placeDetail.name }} </a-descriptions-item>
      <a-descriptions-item  label="所属网格"  :span="3"> {{ placeDetail.gridAddress}} </a-descriptions-item>
      <a-descriptions-item  label="办学类型"> {{ placeDetail.type }} </a-descriptions-item>
      <a-descriptions-item  label="院长姓名"> {{ placeDetail.managerName }} </a-descriptions-item>
      <a-descriptions-item  label="儿童数量" > {{ placeDetail.number }} </a-descriptions-item>
      <a-descriptions-item  label="联系方式"> {{ placeDetail.contact }} </a-descriptions-item>
      <a-descriptions-item  label="位置信息"> {{ placeDetail.address }} </a-descriptions-item>
    </a-descriptions>
    <a-descriptions v-else-if="placeDetail.kind === 1" title="学校" layout="vertical" column="3">
      <a-descriptions-item  label="名称" :span="3"> {{ placeDetail.name }} </a-descriptions-item>
      <a-descriptions-item  label="所属网格" :span="3"> {{ placeDetail.gridAddress }} </a-descriptions-item>
      <a-descriptions-item  label="办学类型"> {{ placeDetail.type }} </a-descriptions-item>
      <a-descriptions-item  label="学校级别"> {{ placeDetail.attribute }} </a-descriptions-item>
      <a-descriptions-item  label="学生人数"  > {{ placeDetail.number }} </a-descriptions-item>
      <a-descriptions-item  label="校长姓名"> {{ placeDetail.managerName }} </a-descriptions-item>
      <a-descriptions-item  label="联系方式" > {{ placeDetail.contact }} </a-descriptions-item>
      <a-descriptions-item  label="位置信息"> {{ placeDetail.address }} </a-descriptions-item>

    </a-descriptions>
    <a-descriptions v-else-if="placeDetail.kind === 2" title="企业" layout="vertical" column="3">
      <a-descriptions-item  label="名称" :span="3"> {{ placeDetail.name }} </a-descriptions-item>
      <a-descriptions-item  label="所属网格" :span="3"> {{ placeDetail.gridAddress }} </a-descriptions-item>
      <a-descriptions-item  label="所属行业"> {{ placeDetail.type }} </a-descriptions-item>
      <a-descriptions-item label="负责人" >   {{ placeDetail.managerName }} </a-descriptions-item>
      <a-descriptions-item  label="联系方式"  > {{ placeDetail.contact }} </a-descriptions-item>
      <a-descriptions-item  label="位置信息" :span="3"> {{ placeDetail.address }} </a-descriptions-item>
    </a-descriptions>
    <a-descriptions v-else-if="placeDetail.kind === 3" title="加油站" layout="vertical" column="3">
      <a-descriptions-item  label="名称" :span="3"> {{ placeDetail.name }} </a-descriptions-item>
      <a-descriptions-item  label="所属网格" :span="3"> {{ placeDetail.gridAddress }} </a-descriptions-item>
      <a-descriptions-item  label="负责人" :span="3">   {{ placeDetail.managerName }} </a-descriptions-item>
      <a-descriptions-item  label="联系方式" > {{ placeDetail.contact }} </a-descriptions-item>
      <a-descriptions-item  label="油品类型" > {{ placeDetail.type }} </a-descriptions-item>
      <a-descriptions-item  label="便利店">   {{ placeDetail.hasStore === '1'?'有':'没有' }} </a-descriptions-item>
      <a-descriptions-item  label="充电桩" >   {{ placeDetail.hasCharge === '1'?'有':'没有'  }} </a-descriptions-item>
      <a-descriptions-item  label="自助加油" > {{ placeDetail.isAutoFill === '1'?'有':'没有'  }} </a-descriptions-item>
      <a-descriptions-item  label="洗车服务" > {{ placeDetail.hasCarWashing === '1'?'有':'没有' }} </a-descriptions-item>
       <a-descriptions-item label="营业时间" :span="3"> {{ placeDetail.openTime }} </a-descriptions-item>
      <a-descriptions-item  label="位置信息" :span="3"> {{ placeDetail.address }} </a-descriptions-item>
    </a-descriptions>
    <a-descriptions v-else-if="placeDetail.kind === 4" title="宗教场所" layout="vertical" column="3">
      <a-descriptions-item  label="名称" :span="3"> {{ placeDetail.name }} </a-descriptions-item>
      <a-descriptions-item  label="所属网格" :span="3"> {{ placeDetail.gridAddress }} </a-descriptions-item>
      <a-descriptions-item label="负责人">   {{ placeDetail.managerName }} </a-descriptions-item>
      <a-descriptions-item  label="联系方式"> {{ placeDetail.contact }} </a-descriptions-item>
      <a-descriptions-item  label="宗教类型"> {{ placeDetail.type }} </a-descriptions-item>
      <a-descriptions-item  label="场所属性"> {{ placeDetail.attribute }} </a-descriptions-item>
      <a-descriptions-item  label="是否为文化遗产"> {{ placeDetail.hasInfluence === '1'?'是':'否' }}</a-descriptions-item>
       <a-descriptions-item  label="开放时间" > {{ placeDetail.openTime }} </a-descriptions-item>
       <a-descriptions-item  label="位置信息"> {{ placeDetail.address }} </a-descriptions-item>
    </a-descriptions>
  </div>
</template>
 
 <script>
export default {
  components: {},
  data() {
    return {
       
    };
  },
  props:['placeDetail'],
  methods: {
    closeDetail(){
      this.$emit('closeDetail')
    }
  },

  created() {},

  mounted() {

  },
};
</script>
 <style lang='less' scoped>
.page {
  background-color: #fff;
  border-radius: 10px;
  padding: 2%;
}
</style>