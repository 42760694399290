<template>
  <div style="height: 100%" id="container">
    <div class="seachBox">

      <a-input
        style="width: 25%"
        placeholder="党支部名称..."
        v-model="inputOption"
      >
        <img
          style="width: 50%"
          slot="prefix"
          src="@/assets/community/gaode.png"
          alt=""
        />
      </a-input>

      <a-button type="primary" style="margin-left: 3%" @click="mapSeach"
        >搜 索</a-button
      >
      <a-button type="primary" style="margin-left: 3%" @click="mapres"
        >重 置</a-button
      >
    </div>
    <div class="detail">
      <detaipage
        :placeDetail="placeDetail"
        @closeDetail="closeDetail"
        v-if="detailShow"
      ></detaipage>
    </div>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import detaipage from "./components/detailPage.vue";
import api from "./api";
const options = [
  {
    lable: "幼儿园",
    value: "0",
  },
  {
    lable: "学校",
    value: "1",
  },
  {
    lable: "企业",
    value: "2",
  },
  {
    lable: "加油站",
    value: "3",
  },
  {
    lable: "宗教场所",
    value: "4",
  },
];
export default {
  components: { detaipage },
  data() {
    return {
      api,
      options,
      map: null,
      selectedItems: [],
      selectOption: "",
      inputOption: "",
      placeDetail: null,
      detailShow: false,
    };
  },

  methods: {
    initAMap() {
      const self = this; // 存储当前的this
      AMapLoader.load({
        key: "6636defcef28a2bf1f1043f154835db6", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0",
        plugins: ["AMap.MouseTool"],
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: "1.1", // AMapUI 缺省 1.1
          plugins: [], // 需要加载的 AMapUI ui插件
        },
      })
        .then((AMap) => {
          self.map = new AMap.Map("container", {
            // 设置地图容器id
            viewMode: "2D", // 是否为3D地图模式
            zoom: 15, // 初始化地图级别
            terrain: true, //开启地形图
            center: [117.303216, 31.803165], // 初始化地图中心点位置
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async mapSeach() {
      this.map.clearMap();
      try {
        const res = await api.conditionSeach({
          kind: this.selectOption,
          name: this.inputOption,
          pageNo: 1,
          pageSize: 100,
        });
        // 过滤掉没有经纬度信息的记录
        const filteredRecords = res.data.records.filter((record) => {
          return record.latitude !== "" && record.longitude !== "";
        });
        if (filteredRecords.length) {
          // 跳转到指定经纬度
          const lnglat = new AMap.LngLat(
            filteredRecords[0].latitude,
            filteredRecords[0].longitude
          );

          this.map.setCenter(lnglat);
          // 渲染点位
          filteredRecords.forEach((element) => {
            if (element.kind === 0) {
              var url = require("@/assets/community/mapIcon/youeryuan.png");
              const marker = new AMap.Marker({
                position: new AMap.LngLat(element.latitude, element.longitude),
                icon: url,
                // 设置了 icon 以后，设置 icon 的偏移量，以 icon 的 [center bottom] 为原点
                offset: new AMap.Pixel(-13, -30),
                extData: element,
              });
              // 点位点击事件
              marker.on("click", (e) => {
                const extData = e.target.getExtData(); // 获取 extData
                this.placeDetail = extData;
                this.detailShow = true;
              });

              this.map.add(marker);
            } else if (element.kind === 1) {
              var url = require("@/assets/community/mapIcon/xiao.png");
              const marker = new AMap.Marker({
                position: new AMap.LngLat(element.latitude, element.longitude),
                icon: url,
                offset: new AMap.Pixel(-13, -30),
                extData: element,
              });
              marker.on("click", (e) => {
                const extData = e.target.getExtData();
                this.placeDetail = extData;
                this.detailShow = true;
              });

              this.map.add(marker);
            } else if (element.kind === 2) {
              var url = require("@/assets/community/mapIcon/qi.png");
              const marker = new AMap.Marker({
                position: new AMap.LngLat(element.latitude, element.longitude),
                icon: url,
                offset: new AMap.Pixel(-13, -30),
                extData: element,
              });
              marker.on("click", (e) => {
                const extData = e.target.getExtData();
                this.placeDetail = extData;
                this.detailShow = true;
              });

              this.map.add(marker);
            } else if (element.kind === 3) {
              var url = require("@/assets/community/mapIcon/you.png");
              const marker = new AMap.Marker({
                position: new AMap.LngLat(element.latitude, element.longitude),
                icon: url,

                offset: new AMap.Pixel(-13, -30),
                extData: element,
              });
              marker.on("click", (e) => {
                const extData = e.target.getExtData(); // 获取 extData
                this.placeDetail = extData;
                this.detailShow = true;
              });

              this.map.add(marker);
            } else if (element.kind === 4) {
              var url = require("@/assets/community/mapIcon/zong.png");
              const marker = new AMap.Marker({
                position: new AMap.LngLat(element.latitude, element.longitude),
                icon: url,
                offset: new AMap.Pixel(-13, -30),
                extData: element,
              });
              marker.on("click", (e) => {
                const extData = e.target.getExtData();
                this.placeDetail = extData;
                this.detailShow = true;
              });

              this.map.add(marker);
            }
          });
        } else {
          this.$message.error("暂无该场所点位信息");
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleChange(e) {
      this.selectOption = e;
    },
    mapres() {
      // 重置清空select的选中项
      this.selectedItems = [];
      this.selectOption = "";
      this.inputOption = "";
      this.detailShow = false;
      this.map.clearMap();
    },
    closeDetail() {
      this.detailShow = false;
    },
    // 跳转点位渲染
    jumpPoint(element) {
      const lnglat = new AMap.LngLat(element.latitude, element.longitude);
      this.map.setCenter(lnglat);
      if (element.kind === 0) {
        var url = require("@/assets/community/mapIcon/youeryuan.png");
        const marker = new AMap.Marker({
          position: new AMap.LngLat(element.latitude, element.longitude),
          icon: url,
          // 设置了 icon 以后，设置 icon 的偏移量，以 icon 的 [center bottom] 为原点
          offset: new AMap.Pixel(-13, -30),
          extData: element,
        });
        // 点位点击事件
        marker.on("click", (e) => {
          const extData = e.target.getExtData(); // 获取 extData
          this.placeDetail = extData;
          this.detailShow = true;
        });

        this.map.add(marker);
      } else if (element.kind === 1) {
        var url = require("@/assets/community/mapIcon/xiao.png");
        const marker = new AMap.Marker({
          position: new AMap.LngLat(element.latitude, element.longitude),
          icon: url,
          offset: new AMap.Pixel(-13, -30),
          extData: element,
        });
        marker.on("click", (e) => {
          const extData = e.target.getExtData();
          this.placeDetail = extData;
          this.detailShow = true;
        });

        this.map.add(marker);
      } else if (element.kind === 2) {
        var url = require("@/assets/community/mapIcon/qi.png");
        const marker = new AMap.Marker({
          position: new AMap.LngLat(element.latitude, element.longitude),
          icon: url,
          offset: new AMap.Pixel(-13, -30),
          extData: element,
        });
        marker.on("click", (e) => {
          const extData = e.target.getExtData();
          this.placeDetail = extData;
          this.detailShow = true;
        });

        this.map.add(marker);
      } else if (element.kind === 3) {
        var url = require("@/assets/community/mapIcon/you.png");
        const marker = new AMap.Marker({
          position: new AMap.LngLat(element.latitude, element.longitude),
          icon: url,

          offset: new AMap.Pixel(-13, -30),
          extData: element,
        });
        marker.on("click", (e) => {
          const extData = e.target.getExtData(); // 获取 extData
          this.placeDetail = extData;
          this.detailShow = true;
        });

        this.map.add(marker);
      } else if (element.kind === 4) {
        var url = require("@/assets/community/mapIcon/zong.png");
        const marker = new AMap.Marker({
          position: new AMap.LngLat(element.latitude, element.longitude),
          icon: url,
          offset: new AMap.Pixel(-13, -30),
          extData: element,
        });
        marker.on("click", (e) => {
          const extData = e.target.getExtData();
          this.placeDetail = extData;
          this.detailShow = true;
        });

        this.map.add(marker);
      }
    },
  },

  created() {},

  mounted() {
    this.initAMap();
    if (this.$route.query.title) {
      setTimeout(() => {
        this.jumpPoint(this.$store.state.item.mapMsg);
      }, 1000);
    }
  },
};
</script>
<style lang='less' scoped>
#container {
  position: relative;
  border-radius: 8px;
  .seachBox {
    position: absolute;
    top: 0;
    height: 7%;
    width: 45%;
    display: flex;
    align-items: center;
    z-index: 999;
  }
  .detail {
    position: absolute;
    top: 2%;
    right: 2%;
    width: 30%;
    z-index: 999;
  }
}
.prefix {
  height: 80%;
}
/deep/.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 40px;
}
/deep/.ant-input-affix-wrapper .ant-input-prefix {
  left: 5px;
}
</style>